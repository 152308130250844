window.__tnt || (window.__tnt = {});
__tnt.video || (__tnt.video = {});
__tnt.video.media || (__tnt.video.media = {});
__tnt.video.blox || (__tnt.video.blox = {});

window.mediaLoad = document.createEvent('Event');
window.mediaUnload = document.createEvent('Event');
window.mediaLoaded = document.createEvent('Event');

mediaLoad.initEvent('tntMediaLoad',true,true,{});
mediaUnload.initEvent('tntMediaUnload',true,true,{});
mediaLoaded.initEvent('tntMediaLoaded',true,true,{});

window.addEventListener('blur', function() {
    if (__tnt.video.advanceTimer) {
        __tnt.video.advanceTimer.pause();
    }
});

window.addEventListener('focus', function() {
    if (__tnt.video.advanceTimer && __tnt.elementOnScreen('.tn-mediaplayer.enhanced-video')) {
        __tnt.video.advanceTimer.resume();
    }
});

window.addEventListener('resize', function() {
    try {
        clearTimeout(timeout);
    } catch { /* No timeout running */ }
    
    // Throttle adjustments with timeout
    var timeout = setTimeout(function() {
                // Adjust player min height
                try {
                    __tnt.video.media.players.forEach(function(player){
                        __tnt.video.media.setHeight(player);
                    });
                } catch { /* Players not available */ }
            }, 500);
});

__tnt.video.timer = function(callback, delay) {
    var timerId, start, remaining = delay;

    this.pause = function() {
        window.clearTimeout(timerId);
        remaining -= Date.now() - start;
    };

    this.cancel = function() {
        window.clearTimeout(timerId);
    };

    this.resume = function() {
        start = Date.now();
        window.clearTimeout(timerId);
        timerId = window.setTimeout(callback, remaining);
    };

    this.resume();
}

__tnt.video.media.playNext = function(player, el) {
    var tnPlayer = el.closest('.tn-mediaplayer');
    if (tnPlayer) {
        var playlistEl = tnPlayer.querySelector('.tn-media-playlist'),
            playlistItem = playlistEl.querySelector('.tn-playlist-item.current'),
            playlistItemNext = playlistItem.nextElementSibling;
    }

    if (playlistItemNext && __tnt.elementOnScreen(player) && !document.hidden) {
        mediaUnload.playlistEl = playlistItemNext;
        mediaLoad.playlistEl = playlistItemNext;

        player.dispatchEvent(mediaUnload);
        player.dispatchEvent(mediaLoad);
    }
}

// Set slide height
__tnt.video.media.setHeight = function(player) {
    var playerArea = player.querySelector('.tn-mediaplayer-area'),
        isAdSlide = playerArea.querySelector('.media-ad');
    
    // If active slide is ad wait for content
    if (isAdSlide) {
        setTimeout(function() {
            __tnt.video.media.setHeight(player);
            }, 1000);
    } else {
        // Clear existing height
        if (playerArea.style.minHeight) playerArea.style.minHeight = 'auto';
        
        // Set height
        var iHeight = playerArea.getBoundingClientRect().height;
        if (iHeight < 250) iHeight = 250;
        playerArea.style.minHeight = iHeight + 'px';
    }
}

// Controls display
__tnt.video.controlDisplay = function(player, descArea) {
    // Remove truncate for comparison
    descArea.classList.remove('truncate');
    
    var descContentCard = descArea.querySelector('.card'),
        descContentHeight = descContentCard.offsetHeight;
    
    if (descContentHeight > descArea.dataset.height) {
        /* Truncate description, and show Read more toggle */
        player.querySelector('.caption-toggle').style.display = 'block';
        descArea.classList.add('truncate');
    } else {
        /* No truncate needed, hide Read more toggle */
        player.querySelector('.caption-toggle').style.display = 'none';
        descArea.classList.remove('truncate');
    }
}

__tnt.video.media.load = function(playlistItem, player){
    var newUuid = playlistItem.getAttribute('data-uuid'),
        playlistItemPrimary = player.querySelector('.tn-media-playlist .tn-playlist-item[data-uuid="'+ newUuid +'"]'),
        playerArea = player.querySelector('.tn-mediaplayer-area'),
        descArea = player.querySelector('.tn-mediaplayer-description'),
        upNextArea = player.querySelector('.tn-media-playlist-up-next'),
        nextItem = player.querySelector('.tn-media-playlist .tn-playlist-item');
    
    if(nextItem){
        var upNextEl = player.querySelector('.tn-media-playlist .tn-playlist-item').cloneNode(true);
    }

    if(playlistItemPrimary){
        var playlistItemVideo = playlistItemPrimary.querySelector('template.video'),
            playlistItemDesc = playlistItemPrimary.querySelector('template.description'),
            upNext = playlistItemPrimary.nextElementSibling;
    }

    if(upNext){ upNextEl = upNext.cloneNode(true); }

    playerArea.innerHTML = '';
    playlistItemVideo = __tnt.scrubElem(playlistItemVideo, '.tnt-asset-link');
    __tnt.template(playlistItemVideo,playerArea,false,[],function(t){
        var newPlayerElements = t.querySelectorAll('video-js.tnt-video, video.tnt-video, .tnt-audio'),
            field59Element = t.querySelector('.tnt-video-container[data-provider="field59"]'),
            mediaElement = t.querySelector('.media-placeholder'),
            mediaAd = t.querySelector('.media-ad'),
            youtubeElement = t.querySelector('.tnt-video-container[data-provider="youtube"], .youtube-video-container'),
            vimeoElement = t.querySelector('.tnt-video-container[data-provider="vimeo"]');
        
        player.querySelector('.tn-mediaplayer-container').classList.remove('tn-media-container-ad');

        if (newPlayerElements.length) {
            newPlayerElements[0].setAttribute("autoplay","true");

            __tnt.video.handleTags(newPlayerElements, function(players){
                if (player.dataset.autoAdvance === 'true') {
                    t.querySelector('.tnt-video, .tnt-audio').addEventListener('tntVideoEnded', function(ev) {
                        __tnt.video.media.playNext(player, ev.target);
                    });
                }
            });

        } else if (field59Element && player.dataset.autoAdvance === 'true') {
            t.querySelector('.tnt-video-container').addEventListener('ended', function(ev) {
                if (ev.target.title != 'Advertisement') {
                    __tnt.video.media.playNext(player, ev.target);
                }
            }, true);

        } else if (mediaAd) {
            var adHtml = document.getElementById('tnt-enhanced-gallery-ad');
            var adCanRefresh = false;
            if (__tnt.ads.slots['fixed-big-ad-enhanced-gallery']?.lazyload == true) {
                if (adHtml.parentNode != document.body) {
                    adCanRefresh = true;
                } else {
                    adCanRefresh = false;
                }
            } else {
                adCanRefresh = true;
            }

            adHtml.parentNode.removeChild(adHtml);
            adHtml.style.display = '';
            
            // Hide controls
            player.querySelector('.caption-toggle').style.display = 'none';
            
            // Append ad
            t.querySelector('.enhanced-player-ad').appendChild(adHtml);

            if (__tnt.ads && adCanRefresh) {
                __tnt.ads.refreshPos('fixed-big-ad-enhanced-gallery');
            }

            player.querySelector('.tn-mediaplayer-container').classList.add('tn-media-container-ad');

            if (player.dataset.autoAdvance === 'true') {
                __tnt.video.advanceTimer = new __tnt.video.timer(function(){
                    if ( !document.body.classList.contains('has-offcanvas--overlay')
                            && !document.body.classList.contains('modal-open') ) {
                        __tnt.video.media.playNext(player, mediaAd);
                    }
                }, 5000);

                __tnt.video.advanceObserver = new IntersectionObserver(function(entries, observer) {
                    if (__tnt.video.advanceTimer) {
                        entries.forEach(function(entry) {
                            if (entry.isIntersecting) {
                                __tnt.video.advanceTimer.resume();
                            } else {
                                __tnt.video.advanceTimer.pause();
                            }
                        });
                    }
                }, {
                    root: null,
                    rootMargin: '0px',
                    threshold: [0, 1.0]
                });
                __tnt.video.advanceObserver.observe(player);
            }

        } else if (mediaElement && player.dataset.autoAdvance === 'true') {
            __tnt.video.advanceTimer = new __tnt.video.timer(function(){
                if ( !document.body.classList.contains('has-offcanvas--overlay')
                        && !document.body.classList.contains('modal-open') ) {
                    __tnt.video.media.playNext(player, mediaElement);
                }
            }, 5000);

            __tnt.video.advanceObserver = new IntersectionObserver(function(entries, observer) {
                if (__tnt.video.advanceTimer) {
                    entries.forEach(function(entry) {
                        if (entry.isIntersecting) {
                            __tnt.video.advanceTimer.resume();
                        } else {
                            __tnt.video.advanceTimer.pause();
                        }
                    });
                }
            }, {
                root: null,
                rootMargin: '0px',
                threshold: [0, 1.0]
            });
            __tnt.video.advanceObserver.observe(player);

        } else if (vimeoElement) {
            vimeoElement.firstElementChild.setAttribute('autoplay', 'true');

        } else if (youtubeElement) {
            var youtubePlayer = youtubeElement.querySelector('iframe');

            if(youtubePlayer){
                if(!youtubePlayer.allow.includes('autoplay')){ youtubePlayer.allow = "autoplay"; }

                if(!youtubePlayer.src.includes('?')){ youtubePlayer.src += '?'; }
                if(!youtubePlayer.src.endsWith('?')){ youtubePlayer.src += '&'; } 

                youtubePlayer.src += 'autoplay=1';
            }
        }

        mediaLoaded.playlistEl = playlistItem;
        playlistItemPrimary.dispatchEvent(mediaLoaded);
    }); // end template call

    descArea.innerHTML = '';
    if (playlistItemDesc) {
        playlistItemDesc = __tnt.scrubElem(playlistItemDesc, '.tnt-asset-link');
        __tnt.template(playlistItemDesc,descArea,false,[],function(t){});
        __tnt.video.controlDisplay(player, descArea);
    }

    if(upNextArea && upNextEl){
        var upNextLinks = upNextEl.querySelectorAll('a');

        if(upNextLinks.length){ for(var unl=0; unl < upNextLinks.length; unl++){
            upNextLinks[unl].addEventListener('click',function(ev){
                ev.preventDefault();

                var playlistEl = ev.target.closest('.tn-playlist-item'),
                    player = playlistEl.closest('.tn-mediaplayer');

                mediaUnload.playlistEl = playlistEl;
                mediaLoad.playlistEl = playlistEl;
                player.dispatchEvent(mediaUnload);
                player.dispatchEvent(mediaLoad);
            });
        }}

        upNextArea.innerHTML = '';
        upNextArea.appendChild(upNextEl);
    }
};

document.addEventListener("DOMContentLoaded", function(event) {
    var mediaPlayers = document.querySelectorAll('.tn-mediaplayer'),
        mediaPlayerActive = mediaPlayers[0];
    
    __tnt.video.media.players || (__tnt.video.media.players = mediaPlayers);
    __tnt.video.media.player_active || (__tnt.video.media.player_active = mediaPlayerActive);
    
    if(mediaPlayers.length){
        for (var mp = 0; mp < mediaPlayers.length; mp++) {
            let player = mediaPlayers[mp],
                playlistItems = player.querySelectorAll('.tn-playlist-item'),
                descArea = player.querySelector('.tn-mediaplayer-description'),
                loader = player.querySelector('.tn-mediaplayer-loading');
            
            descArea.dataset.height = descArea.offsetHeight;
            
            setTimeout(function(){ 
                if (loader) loader.classList.remove('active');
            }, 500);
            
            // Init player height
            __tnt.video.media.setHeight(player);
            
            // Caption control visibility
            __tnt.video.controlDisplay(player, descArea);
            
            // Create initial auto advance listener for F59
            if (player.dataset.autoAdvance === 'true') {
                let videoContainer = player.querySelector('.tnt-video-container');
                if (videoContainer.dataset.provider === 'field59'){
                    videoContainer.addEventListener('ended', function(ev) {
                        if (ev.target.title != 'Advertisement') {
                            __tnt.video.media.playNext(player, ev.target);
                        }
                    }, true);
                }
            }

            // Unload current media from player
            player.addEventListener('tntMediaUnload',function(ev){
                var player = ev.target.closest('.tn-mediaplayer'),
                    playlistEl = player.querySelector('.tn-media-playlist .tn-playlist-item[data-uuid="'+ ev.playlistEl.getAttribute('data-uuid')+'"]'),
                    loader = ev.target.querySelector('.tn-mediaplayer-loading'),
                    playerArea = ev.target.querySelector('.tn-mediaplayer-area'),
                    vjsEl = player.querySelector('.video-js');
                
                if (player.dataset.autoAdvance === 'true' && typeof __tnt.video.advanceObserver != 'undefined') {
                    __tnt.video.advanceObserver.disconnect();
                }
                
                if (playerArea.querySelector('.media-ad')) {
                    if (__tnt.ads) {
                        __tnt.ads.refreshPos('fixed-big-ad-enhanced-gallery', {destroy:true});
                    }
                    
                    var adElement = document.getElementById('tnt-enhanced-gallery-ad');
                    adElement.parentNode.removeChild(adElement);
                    adElement.style.display = 'none';
                    ev.target.appendChild(adElement);
                }
                
                if(playlistEl){
                    var current = playlistEl.closest('.tn-media-playlist').querySelector('.tn-playlist-item.current');
                }
                
                if(loader){ loader.classList.add('active'); }
                
                if(playerArea){
                    playerArea.style.display = "none";
                    
                    if(vjsEl){
                        setTimeout(function(){
                            videojs(vjsEl).dispose();
                            __tnt.video.decompose(vjsEl.id);
                        }, 100);
                    } else {
                        var videoEl = playerArea.querySelector('video');
                        if (videoEl) {
                            videoEl.pause();
                        }
                    }
                }
                
                if(current){ current.classList.remove('current'); }
            });
            
            // load new media into player
            player.addEventListener('tntMediaLoad',function(ev){
                __tnt.video.media.load(ev.playlistEl,ev.target);
            });
            
            // new media loaded into player
            player.addEventListener('tntMediaLoaded',function(ev){
                var tnPlayer = ev.target.closest('.tn-mediaplayer'),
                    playlistEl = tnPlayer.querySelector('.tn-media-playlist .tn-playlist-item[data-uuid="'+ ev.playlistEl.getAttribute('data-uuid')+'"]'),
                    loader = tnPlayer.querySelector('.tn-mediaplayer-loading'),
                    playerArea = tnPlayer.querySelector('.tn-mediaplayer-area');
                
                if(playlistEl && loader && playerArea){
                    loader.classList.remove('active');
                    playerArea.style.display = 'grid';
                    playlistEl.classList.add('current');
                }
            });
            
            // initialize playlist event handling
            if(playlistItems.length){ for(var pl=0; pl < playlistItems.length; pl++){
                var playlistItem = playlistItems[pl],
                    playlistLinks = playlistItem.querySelectorAll('a');
                
                if(playlistLinks.length){ for(var i=0; i < playlistLinks.length; i++){
                    if(!playlistLinks[i].classList.contains('tnt-download')){
                        playlistLinks[i].addEventListener('click',function(ev){
                            ev.preventDefault();
                            
                            var playlistEl = ev.target.closest('.tn-playlist-item'),
                                player = playlistEl.closest('.tn-mediaplayer');
                            
                            if(!playlistEl.classList.contains('current')){
                                mediaUnload.playlistEl = playlistEl;
                                mediaLoad.playlistEl = playlistEl;
                                
                                player.dispatchEvent(mediaUnload);
                                player.dispatchEvent(mediaLoad);
                            }
                            
                            return false;
                        });
                    };
                }}; // end playlsitLinks
            }}; // end playlistItems
            
            // initialize first playlist item
            if(player && player.querySelector('.tn-media-playlist .tn-playlist-item')){
                player.querySelector('.tn-media-playlist .tn-playlist-item').classList.add('current');
            }
        }
    }; // end mediaplayers
});